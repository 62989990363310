import { useState, ReactNode } from 'react';
import axios from 'axios';

import { Token, Endpoint } from '../';

export type ReturnType = ClaimRequestReturnType & { request: () => void };

type PropsType = {
  id: string;
  children: (claim: ReturnType) => ReactNode;
}

const defaultClaim: ClaimType | null = null;

interface ErrorStatusListType {
  [key: number]: RequestErrorStatusType;
}; 

const errorStatuses: ErrorStatusListType = {
  401: 'unAuthorized',
  403: 'forbidden'
}

async function get<T>(path: string): Promise<T> {
  return (await axios.get(path)).data
}

const Request = ({ children, id }: PropsType) => {
  const [claim, setClaim] = useState<ClaimType | null>(defaultClaim);
  const [status, setStatus] = useState<RequestStatusType>('notSent');
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);

  const token = Token.get().token;

  const getClaim = async () => {
    setStatus('pending')
    get<ClaimRequestReturnType>(Endpoint().Rewards.Claim({ id, token })).then(
      fetchedClaim => {
        var claimData = fetchedClaim.claim;
        if(claimData?.expiry_time) { claimData.expiry_time = new Date(claimData.expiry_time) }
        setClaim(fetchedClaim.claim);
        setStatus('complete')
      }
    ).catch(
      error => {
        setErrorMessage(error?.response?.data?.error)
        setStatus(errorStatuses[error?.response?.status] || 'unknownError')
      }
    )
  }

  const data = {
    errorMessage,
    claim,
    status,
    request: getClaim
  }

  return(
    <>{children(data)}</>
  )
}

export default Request

