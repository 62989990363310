import React from 'react';

import '../../i18n/config'

import { Image, Transformation } from 'cloudinary-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Logo from './Logo.png'

type PropsType = { courier: CourierType }

const CourierPhoto = ({ courier: { photo_url: photoUrl } }: PropsType) => {
  if(!photoUrl) {
    return <></>
  }

  return(
    <Row className="justify-content-center">
      <Image src={photoUrl} className="rounded-circle" width={75} height={75} crop="crop">
        <Transformation width="75" height="75" crop="scale"/>
      </Image>
    </Row>
  )
}

const Banner = ({ courier }: PropsType) => {
  const { tier_key: tierKey } = courier
  const { t } = useTranslation()

  return(
    <div>
      <div>
        <Image src={Logo} width={125} fluid='true' className="float-left p-2"/>
      </div>
      <div>
        <Container className="p-3 text-center justify-content-center pt-6">
          <CourierPhoto courier={courier} />
          <Row className="justify-content-center h4 pt-3">
            {t('banner.title')}
          </Row>
          <Row className="justify-content-center">
            {t('banner.description1', {
              tier: t(`tier.${tierKey}.name`),
              endDate: moment().endOf('quarter').format('DD MMM'),
              startDate: moment().startOf('quarter').format('DD MMM')
            })}
            <br />
            {t('banner.description2')}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Banner
