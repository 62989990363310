import React from 'react';
import moment from 'moment';

const Footer = () => {
  return(
    <footer className="text-center sticky-footer">
      <div className="text-gray font-weight-normal">©{moment().year()} www.sherpa.net.au&nbsp;|&nbsp;ABN: 24 168 403 307&nbsp;|&nbsp;ACN: 168 403 307</div>
    </footer>
  )
}

export default Footer
