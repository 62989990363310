import React from 'react';

import RewardItem from './Item'
import { useTranslation } from 'react-i18next';

interface ListRewardsType {
  rewards: RewardType[]
}

const ListRewards = ({ rewards }: ListRewardsType) => {
  const { t } = useTranslation()

  if(rewards.length > 0){
    return(
      <div>
        {rewards.map((reward: RewardType) => <RewardItem key={reward.id} reward={reward} />)}
      </div>
    )
  }
  return (
    <div className='mt-5 pt-5 text-center'>
      <h5 className='text-gray'>{t('noRewards')}</h5>
    </div>
  )
}

export default ListRewards;
