const tokenKey = 'token';

const store = (token: TokenType | null) => {
  localStorage.setItem(tokenKey, JSON.stringify(token || {}));
};

const get = () => {
  var tokenData = JSON.parse(localStorage.getItem(tokenKey) || "{}") ;

  const expiry = new Date(tokenData.expiry);

  return({
    token: tokenData.token,
    expiry,
    expired: expiry <= new Date()
  })
}

const Token = { store, get }

export default Token;
