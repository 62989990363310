import React, { useState } from 'react';

import { Analytics } from '../../Services';

import Button from 'react-bootstrap/Button';
import { GenericModal as Modal } from '../../shared';
import Reward from './Reward'

interface PropsType {
  reward: RewardType
}

type ClickAbleType = PropsType & {
  handleClick: () => void;
  state?: boolean
}

const RedeemButton = ({ reward: { code, claimable }, handleClick }: ClickAbleType) => {
  if(!code && !claimable) { return(<></>) };

  return(
    <Button variant="primary" className="btn-md" onClick={handleClick}>
      Redeem
    </Button>
  )
}

const RewardsModal = ({ reward }: PropsType) => {
  const {
    code,
    id,
    title,
  } = reward

  const [copied, setCopied] = useState(false)
  const identifier = `${id}-${title}`

  const handleClose = () => {
    setCopied(false)
  }

  const event = (category: string) => {
    Analytics().event({ action: identifier, category });
  }

  const handleCopy = () => {
    if(!code) { return }

    event('codeCopied');
    navigator.clipboard.writeText(code || '')
    setCopied(true);
  }

  const handleClick = () => {
    event('linkClicked');
  }

  return (
    <Modal
      id={identifier}
      onClose={handleClose}
      OpenButton={({ handleClick }) => (
        <div className="h-100 d-flex align-items-center mr-1 mb-2 mb-sm-0">
          <RedeemButton reward={reward} handleClick={handleClick} />
        </div>
      )}
    >
      {() => (
        <Reward
          reward={reward}
          copied={copied}
          handleClick={handleClick}
          handleCopy={handleCopy}
        />
      )}
    </Modal>
  )
}

export default RewardsModal;
