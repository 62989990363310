import React from 'react';

import ClaimButton from './ClaimButton';
import ShowClaim from './ShowClaim';
import { Pending, ErrorModal } from '../../../shared';
import { ClaimRequest } from './../../../Services/Requests';

import type { ClickAbleType } from '../Reward';

const ExpiredClaim = () => {
  // generate a time in not too distant past to trigger expiry,
  const expiry_time: Date = new Date(new Date().getTime() - 60);

  return <ShowClaim claim={{ expiry_time }} />
}

const ClaimContent = ({ claim }: { claim: ClaimType | null }) => {
  return claim ? <ShowClaim claim={claim} /> : <></>
}

const Claim = ({ reward, handleClick }: ClickAbleType) => {
  if(!reward.claimable) { return <></> };

  return(
    <ClaimRequest id={reward.id} >
      {({ status, request, claim }) => {
        const getClaim = () => {
          request();
          handleClick();
        }

        switch(status) {
          case 'notSent':
            return <ClaimButton handleClick={getClaim} text='Generate Barcode Now' />
          case 'pending':
            return <Pending />
          case 'unAuthorized':
          case 'unknownError':
            return <ErrorModal type={status} />
          case 'forbidden':
            return <ExpiredClaim />
          default:
            return <ClaimContent claim={claim} />
        }
      }}
    </ClaimRequest>
  )
}

export default Claim
