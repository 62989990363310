const Environment = () => {
  // build env
  const development = process.env.NODE_ENV === 'development';
  const production = process.env.NODE_ENV === 'production';
  const test = process.env.NODE_ENV === 'test';

  // deployment env
  const subdomain = development ? '' : window.location.hostname.split('.')[0]
  const dev =  subdomain === 'dev';
  const prod = production && subdomain === 'rewards';
  const qa =   subdomain === 'qa';

  let name
  const analyticsId = process.env.REACT_APP_ANALYTICS_ID || '';

  if(production && subdomain !== 'rewards') {
    name = subdomain.replace(".", "");
  } else {
    name = process.env.NODE_ENV
  }

  return({
    development, // local build
    dev, // on dev site
    qa, // on qa site
    prod, // on prod site
    production, // production build
    test, // testing
    name, // 'dev' | 'qa' | 'production' | 'development'  | 'test'
    analyticsId // string || null
  });
}

export default Environment;
