import React from 'react';

import Banner from './Banner'

type PropsType = { courier: CourierType }

const Header = ({ courier }: PropsType) => {
  return(
    <div className="bg-gradient-primary font-weight-bold" style={{height: "19rem", marginBottom: "-1rem"}}>
      <Banner courier={courier} />
    </div>
  )
}

export default Header
