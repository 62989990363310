import { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

import { Endpoint, Token } from '../';

type PropsType = {
  children: (reward: RewardRequestReturnType) => ReactNode;
}

type GetReturnType = RewardRequestReturnType & {
  token: TokenType
};

const defaultRewards: RewardType[] = [];
const defaultCourier: CourierType | null = null;

interface ErrorStatusListType {
  [key: number]: RequestErrorStatusType;
}; 

const errorStatuses: ErrorStatusListType = {
  401: 'unAuthorized',
  403: 'forbidden'
}

async function get<T>(path: string): Promise<T> {
  return (await axios.get(path)).data
}

const Request = ({ children }: PropsType) => {
  const [rewards, setRewards] = useState<RewardType[]>(defaultRewards);
  const [courier, setCourier] = useState<CourierType | null>(defaultCourier);
  const [status, setStatus] = useState<RequestStatusType>('notSent');
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);

  const getRewards = async () => {
    setStatus('pending')
    get<GetReturnType>(Endpoint().Rewards.Index).then(
      fetchedRewards => {
        setRewards(fetchedRewards.rewards);
        setCourier(fetchedRewards.courier);
        Token.store(fetchedRewards.token);
        setStatus('complete')
      }
    ).catch(
      error => {
        setRewards([])
        setErrorMessage(error?.response?.data?.error)
        setStatus(errorStatuses[error?.response?.status] || 'unknownError')
      }
    )
  }

  useEffect(() => { getRewards() }, [])

  const data = {
    courier,
    errorMessage,
    rewards,
    status,
  }

  return(
    <>{children(data)}</>
  )
}

export default Request
