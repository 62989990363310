import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import Modal from './Modal';

interface RewardItemType {
  reward: RewardType
}

const RewardLogo = ({ reward: { logo } }: RewardItemType) => (
  <Container className="d-flex align-items-center pb-0 border" style={{ width: '100px', height: '100px', borderRadius: "4px" }} >
    <Image className="p-2" style={{height: '75px'}} src={logo} rounded />
  </Container>
)

const Redeem = ({ reward }: RewardItemType) => {
  const { code, link, claimable } = reward

  if(code || claimable){
    return(<Modal reward={reward} />)
  } else if(link) {
    return(
      <div className="h-100 d-flex align-items-center mr-1 mb-2 mb-sm-0">
        <Button variant="primary" href={reward.link} className="btn-md">
          Redeem
        </Button>
      </div>
    )
  }

  return(<></>)
}

const RewardItem = ({ reward }: RewardItemType) => (
  <Card className="mb-2">
    <Row className="p-sm-2rm">
      <Col md={2} className="p-3 p-sm-0">{reward.logo && <RewardLogo reward={reward} />}</Col>
      <Col md={8} className="pb-3 pt-3 pl-sm-0 pr-sm-0">
        <Row className="text-dark">
          <h5>{reward.title}</h5>
        </Row>
        <Row className="text-gray pt-1 font-weight-bold">
          {reward.blurb}
        </Row>
        <Row className="text-dark pt-3">
          {reward.description}
        </Row>
      </Col>
      <Col md={2}>{<Redeem reward={reward} />}</Col>
    </Row>
  </Card>
)

export default RewardItem;
