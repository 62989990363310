import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Barcode from './Barcode';
import CountDown from './CountDown';

interface PropsType {
  claim: ClaimType;
};

const ShowClaim = ({ claim: { hint, code, expiry_time } }: PropsType) => {
  const [expired, setExpire] = useState<boolean>(!!(expiry_time && expiry_time < new Date()));
  var info = <></>;

  if(expired) {
    info = (<>
      <Row className='justify-content-center text-dark font-weight-bold'>
        You've claimed this discount today, a new barcode will appear tomorrow
      </Row>
    </>)
  } else {
    info = (<>
      <Row className='justify-content-center text-dark font-weight-bold'>{hint}</Row>
      {code && <Row className='justify-content-center'><Barcode code={code} /></Row>}
    </>)
  }

  const counter = (
    expiry_time && <Row className='justify-content-center'><CountDown expiry={expiry_time} onExpire={() => setExpire(true)} /></Row>
  ) 

  return(
    <>
      <Col className='pt-4'>
        {info}
        {counter}
      </Col>
    </>
  )
}

export default ShowClaim;
