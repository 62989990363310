import Location from './Location';

const Endpoint = () => { 
  const { search } = Location();

  const rootEndpoint = `${process.env.REACT_APP_API_ROOT}`
  const apiEndpoint = `${rootEndpoint}/api/1`

  return({
    Rewards: {
      Index: `${apiEndpoint}/rewards${search}`,
      Claim: ({id, token}: { id: string, token: string }) => (`${apiEndpoint}/rewards/${id}/claim?token=${token}`)
    },
    Courier: {
      Dashboard: `${rootEndpoint}/couriers`,
      Rewards: `${rootEndpoint}/couriers/request_token?post_action=partner_reward`
    },
  })
}

export default Endpoint
