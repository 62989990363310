import React from 'react';

import Image from 'react-bootstrap/Image';
import ErrorImage from './Error.png'

import { GenericModal as Modal } from './'
import { Endpoint } from './../Services';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

const content = (type: string) => {
  return({
    link: (type === 'unAuthorized') ? Endpoint().Courier.Rewards : Endpoint().Courier.Dashboard,
    key: (type === 'unAuthorized') ? 'unauthorized' : 'generic'
  })
}

const ErrorModal = ({ type }: { type: 'unAuthorized' | 'unknownError' }) => {
  const { t } = useTranslation()
  const { key, link } = content(type)
  const title = t(`errors.${key}.title`)

  return(
    <Modal
      id={title}
      closable={false}
      Header={
        <div className='mx-auto'>
          <Image src={ErrorImage} width={50} fluid className="w-auto p-2"/>
          <div>
            <h5 className="text-dark mt-4">{title}</h5>
          </div>
        </div>
      }
    >
      {() => (
        <>
          <div>
            {t(`errors.${key}.body`)}
          </div>
          <Button href={link} variant="primary" className="mt-4 btn-lg">
            {t(`errors.${key}.cta`)}
          </Button>
        </>
      )}
    </Modal>
  )
}

export default ErrorModal
