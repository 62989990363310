import React, { useState } from 'react';

import { Analytics } from '../Services'
import Modal from 'react-bootstrap/Modal'

interface ModalRenderType {
  handleClick: () => void
};

interface PropsType {
  id: string;
  animation?: boolean;
  children?: (arg0: ModalRenderType) => React.ReactNode;
  closable?: boolean;
  OpenButton?: (arg0: ModalRenderType) => React.ReactNode;
  onClose?: () => void;
  onShow?: () => void;
  Header?: React.ReactNode;
}

const GenericModal = ({
  id,
  onClose,
  onShow,
  OpenButton,
  Header,
  animation=true,
  children
}: PropsType) => {
  const [show, setShow] = useState(!OpenButton);

  const handleShow = () => {
    setShow(true)
    Analytics().event({ action: id, category: 'modalOpen' });
    onShow && onShow();
  }

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  }

  return (
    <>
      {OpenButton && OpenButton({ handleClick: handleShow})}
      <Modal show={show} onHide={handleClose} animation={animation}>
        <Modal.Header closeButton={!!OpenButton} className='pb-0 text-center'>
          {Header}
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          {(children && children({ handleClick: handleClose }))}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GenericModal
