import React from 'react';

import Button from 'react-bootstrap/Button';

interface PropsType {
  handleClick: () => void,
  text: string
}

const ClaimButton = ({ handleClick, text }: PropsType) => (
  <Button variant="primary" className="btn-md mt-4" onClick={handleClick}>
    {text}
  </Button>
)

export default ClaimButton
