import Environment from './Environment';

const Location = () => {
  const { dev, qa } = Environment()
  const baseLocation = window.location || {};

  const subdomain = () => {
    if(dev) {
      return 'dev.'
    } else if(qa) {
      return 'qa.'
    } else {
      return ''
    }
  }

  const extraFields = {
    subdomain: subdomain()
  };

  return({
    ...baseLocation,
    ...extraFields
  });
}

export default Location;
