import React, { useState } from 'react';

import ImageWrapper from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown';

import Claim from './Claim';

interface SectionType {
  reward: RewardType
};

interface PropsType {
  reward: RewardType,
  copied: boolean,
  handleClick: () => void,
  handleCopy: () => void,
};

export type ClickAbleType = SectionType & {
  handleClick: () => void;
  state?: boolean
};

export const TermsAndConditions = ({ reward: { terms } }: SectionType) => {
  if(!terms) { return(<></>) }

  return(
    <div className="mt-4 text-gray font-weight-bold">
      <small >
        <ReactMarkdown>{terms}</ReactMarkdown>
      </small>
    </div>
  )
};

export const LinkButton = ({ reward: { link }, handleClick }: ClickAbleType) => {
  if(!link) { return(<></>) };

  return(
    <Button variant="primary" href={link} onClick={handleClick} className="mt-4">
      Visit Partner Website to Redeem
    </Button>
  )
};

export const CodeBox = ({ reward: { code }, state, handleClick }: ClickAbleType) => {
  if(!code) { return <></> };
  
  return(
    <div onClick={handleClick} className="mt-4">
      <div className="w-75 m-auto bg-gray">
        <input
          role="button"
          className={`${state ? "copied" : "copy-field"} mt-0 mb-0 mr-auto ml-auto text-center w-90 form-control border-bottom-0`}
          type="text"
          value={code}
          readOnly/>
      </div>
    </div>
  )
}

export const RewardInformation = ({ reward: { title, blurb, description } }: SectionType) => (
  <>
    <h5 className="text-dark mt-4">{title}</h5>
    <div className="text-gray font-weight-bold mb-4">
      {blurb}
    </div>
    <div className="text-dark font-weight-bold">
      {description}
    </div>
  </>
)

export const Image = ({ reward: { logo } }: SectionType) => (
  <Row className="justify-content-center pt-2">
    <ImageWrapper src={logo} rounded width={55}/>
  </Row>
)

const Reward = ({ copied, reward, handleCopy, handleClick }: PropsType) => {
  const [claimed, setClaimed] = useState<boolean>(false);

  const handleClaim = () => {
    handleClick();
    setClaimed(true);
  }

  return(
    <>
      <Image reward={ reward } />
      {!claimed && <>
        <RewardInformation reward={reward} />
        <CodeBox reward={reward} state={copied} handleClick={handleCopy} />
        <TermsAndConditions reward={reward} />
        <LinkButton reward={reward} handleClick={handleClick} />
      </>}
      <Claim reward={reward} handleClick={handleClaim} />
    </>
  )
} 

export default Reward
