import { useState, useEffect } from 'react';

import timerExpired from '../../../images/icons/timer-expired.png';
import timer from '../../../images/icons/timer.png';
import Image from 'react-bootstrap/Image';

type PropsType = {
  expiry: Date;
  onExpire: () => void;
}

type TimeComponentType = {
  hours: number;
  minutes: number;
  seconds: number;
} | 'expired';

const ShowTimer = ({ content, expired }: { content: string, expired: boolean }) => (
  <div
    className='d-flex align-items-center mt-2'
    style={{ padding: '0 10px', height: '45px', background: 'rgb(246, 249, 251)', borderRadius: '22.5px' }}
  >
    <div className='d-flex align-items-center' style={{ height: '20px', color: expired ? 'rgb(138, 150, 156)' : 'rgb(38, 50, 56)' }}>
      <Image style={{ height: '100%', verticalAlign: 'middle' }} src={expired ? timerExpired : timer} />
      <span className='ml-1' >{content}</span>
    </div>
  </div>
)

const timeComponentsFromExpiry = (expiry: Date) => {
  const diff = expiry.getTime() - new Date().getTime();

  if(diff < 0) {
    return('expired');
  } else {
    return({
      hours: Math.floor((diff % 86400000) / 3600000),
      minutes: Math.floor((diff % 3600000) / 60000),
      seconds: Math.floor((diff % 60000) / 1000)
    });
  } 
}

const Expiry = ({ expiry, onExpire }: PropsType) => {
  const [timeComponents, setTimeComponents] = useState<TimeComponentType>(timeComponentsFromExpiry(expiry));

  const tick = () => {
    const components = timeComponentsFromExpiry(expiry);

    setTimeComponents(components);
    if (components === 'expired') { onExpire() }
  }

  useEffect(() => {
    const intervalId = setInterval(tick, 1000);
    return () => clearInterval(intervalId);
  })

  if(timeComponents === 'expired') {
    return(<ShowTimer expired={true} content={'Expired'} />)
  }

  const { hours, minutes, seconds } = timeComponents;

  const hours_string = hours > 0 ? `${hours}:` : ''
  const minutes_string = minutes.toString().padStart(2, '0')
  const seconds_string = seconds.toString().padStart(2, '0')

  return(
    <ShowTimer expired={false} content={`Expires in ${hours_string}${minutes_string}:${seconds_string}`} />
  );
}

export default Expiry;
