import React from 'react';
import './App.css';

import './i18n/config'
import { Router, Route } from "react-router-dom";

import Container from 'react-bootstrap/Container';

import { Header, Rewards as RewardList, Footer } from './Components'
import { RewardRequest, Analytics } from './Services';
import { Pending, ErrorModal } from './shared'

const HandleSuccessfulRequest = ({ courier, rewards }: RewardRequestReturnType) => (
  <>
    {courier && <Header courier={courier} />}
    <Container>
      <RewardList rewards={rewards} />
    </Container>
    <Footer />
  </>
);

const RewardPage = () => (
  <RewardRequest>
    {(request: RewardRequestReturnType) => {
      const { status } = request

      switch(status) {
        case 'pending':
          return <Pending />
        case 'unAuthorized':
        case 'unknownError':
          return <ErrorModal type={status} />
        case 'complete':
          return <HandleSuccessfulRequest {...request} />
        default:
          return <></>
      }
    }}
  </RewardRequest>
)

const App = () => (
  <Router history={Analytics().trackedBrowserHistory()}>
    <Route component={RewardPage} />
  </Router>
);

export default App;
