import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history';
import { Environment } from './'

type EventType = { action: string, category: string };

const Analytics = () => {
  const trackingId = Environment().analyticsId;

  const trackedBrowserHistory = () => {
    const history = createBrowserHistory();
    if(trackingId) {
      ReactGA.initialize(trackingId)

      history.listen(({ location }) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      })
    };

    return(history)
  };

  const AnalyticsEvent = ({ action, category }: EventType) => {
    if(!trackingId) { return }

    ReactGA.event({ action, category })
  };

  return({
    event: AnalyticsEvent,
    trackedBrowserHistory
  });
};

export default Analytics
