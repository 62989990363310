import React from 'react';

import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

interface PropsType {
  className?: string;
}
const Pending = ({ className }: PropsType) => (
  <Container className={className}>
    <Row className="justify-content-md-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Row>
  </Container>
)

export default Pending
